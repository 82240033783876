import { ArticleIntroLayout, ListContainer } from "styles/article-styles";
import chickenTinderMacImg from "assets/chicken-tinder-mac.png";
import chickenTinderMobileImg from "assets/chicken-tinder-mobile.png";
import { SiGithub } from "react-icons/si";
import { MdDevices } from "react-icons/md";
import * as React from 'react';
export default {
  ArticleIntroLayout,
  ListContainer,
  chickenTinderMacImg,
  chickenTinderMobileImg,
  SiGithub,
  MdDevices,
  React
};